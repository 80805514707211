import styled from "styled-components";
import arrow from "../../../assets/images/icons/arrow-right.svg"
import {device} from '../../../theme'

export const SectionWrap = styled.section `
    position: relative;
    padding:40px 40px;
    max-width:1600px;
    margin:0 auto;
    @media (max-width: 400px) {
      padding:15px 10px;
      h3{
          text-align:center;
      }
    } 
    .journey-type{
        background: #2EB369;
        padding:5px 20px;
        border-radius: 30px;
        color: #fff;
        text-align: center;
        font-size: 1.75rem;
        /* line-height: 3rem; */        
        display:inline-block;
        margin-right: 20px;
        margin-top:40px;
         @media ${device.small}{
             width:100%;    
         }
    }
    .feature-item{
    	border-radius:25px 10px 25px 10px !important;
    	background-size:cover;
    	background-position:center center;
    	background-repeat:no-repeat;
    	height:400px;
    	display:flex;
    	justify-content:flex-end;
    	flex-direction:column; 
    	flex:1;
    	.category-wrap{
    		position:absolute;
    		top:15px;
    		right:15px;
    		color:#fff;

    		span{
    			float:right;
    			padding:3px 10px;
    			font-size:12px;
                border-radius:15px;
    		}
    	}  
    	&:before{
    		content:'';
    		width:100%;
    		height:70%;
    		position:absolute;
    		bottom:0;
    		left:0;
    		z-index:0;
    		border-radius:25px 10px 25px 10px;
    	}
    }
  	.ant-list-pagination{
		text-align:left;
        margin-top:30px;    
        margin-bottom:50px;
        input{
            border-radius: 14px;
        }
	}
`;