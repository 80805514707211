import styled from 'styled-components'
import {Link} from 'gatsby'
import {device, theme} from '../../../theme'

export const BoxImgInner = styled.div `
    width:calc(100% + 50px);
    position:absolute;
    margin: 0 auto;
    height:100%;
    background:#fff;
    border-radius:20px;
    padding:20px;
    display:block;
    height:140px;
    left:-5px;
    bottom:-45px;
    overflow:visible;
    z-index:99999;
    -webkit-box-shadow: -1px 6px 13px 0px rgba(50, 50, 50, 0.15);
    -moz-box-shadow:    -1px 6px 13px 0px rgba(50, 50, 50, 0.15);
    box-shadow:         -1px 6px 13px 0px rgba(50, 50, 50, 0.15);
`;

export const BoxImgMedia = styled.div `
    transition: ${props => props.theme.transition};
`;

export const BoxImgContent = styled.div `

`;

export const HeadingWrap = styled.h5 `
    font-weight: ${props => props.fontweight || 500};
    line-height: 1.75rem;
    color:${theme.colors.headingColor};
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight:800;
    margin-bottom: ${props => props.mb || 0};
    @media ${device.large}{
        font-size:1.25rem;    
    }
    &:hover{
        color: ${props => props.theme.colors.themeColor};
    }
    font-size:1.25rem;
`;


export const Tag = styled.span `
    font-weight: ${props => props.fontweight};
    line-height: ${props => props.lineHeight};
    margin-bottom: ${props => props.mb};
    padding:0px 10px;
    font-size:12px;
    margin-left: ${props => props.ml};
    border-radius:4px;
    float:left;
    background: ${props => props.background};
    color: ${props => props.color || '#696969'};
`;

export const TextWrap = styled.p `
    font-weight: ${props => props.fontweight};
    line-height: ${props => props.lineHeight};
    margin-bottom: ${props => props.mb};
    margin-bottom:0;
    margin-top:5px;
    line-height:1.1rem;
    width:100%;
    color: ${theme.colors.headingColor};
    -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;



`;

export const BoxImgLink = styled(Link) `
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    text-indent: -999999px;
`;
 
export const BoxImgWrapper = styled.div `
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    flex-direction: column;
    height: 100%;
    transition: 400ms transform, 200ms top;
    transform: translateY(0px);
    margin-bottom:50px;
    button{
        background:none;
        color:#FD6D4D;
        font-weight:700;
        padding:0;
        line-height:15px;
        font-size:14px;
        height:30px;
        margin-top:5px;
        border-bottom:1px dotted #FD6D4D;
    }
    &:hover{
        transform: translateY(-5px);
        ${BoxImgLink}{
            opacity: 1;
            visibility: visible;
        }
        ${BoxImgMedia}{
            transform: translateY(-5px);
        }
        
    }
`; 